import { Button, Form, Input} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { userActionCreators } from "../redux/user";
import { useEffect } from "react";
import triggerNotification from "../hooks/triggerNotification";

const RegisterBuyer = ({ isModalVisible, setUserType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { isAuthenticated, info } = useSelector((state) => state.user);
  const { registerUser } = bindActionCreators(userActionCreators, dispatch);

  const onFinish = async (values) => {
    const res = await registerUser(values);
    if (res.status === "not ok") triggerNotification({
      type: "error",
      message: res.message,
    });
    if (res.status === "ok") {
      navigate("/complete-account");
      isModalVisible(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && info.role === "1") {
      navigate("/account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return (
    <div>
      <div className="reg-register-seller-container">
        <div className="reg-register-container seller-form">
          <div className="reg-regiseter-seller-contents">
            <h2 className="reg-txt-register">The Losode Marketplace</h2>
            <p className="reg-txt-setup">
              Gain access to millions of independent fashion designers and shop
              with ease. Look out for incredible bargains.
            </p>

            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
            >
              <div className="reg-inputs-container">
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/g,
                      message: "Only letters are allowed",
                    },
                  ]}
                >
                  <Input className="reg-input" />
                </Form.Item>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/g,
                      message: "Only letters are allowed",
                    },
                  ]}
                >
                  <Input className="reg-input" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email address"
                  rules={[
                    {
                      type: "email",
                      message: "Use a valid email address",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="reg-input"
                    // placeholder="Email Address"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  extra="Password must be longer than 8 characters and must contain at least 1 capital letter, 1 small letter and 1 number "
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                    {
                      pattern:
                        /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,300})$/,
                      message:
                        "The password you have entered does not meet our requirements. Please change it for your enhanced security",
                    },
                  ]}
                >
                  <Input.Password
                    className="reg-input"
                    // placeholder="Password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item
                  name="c_password"
                  label="Confirm password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="reg-input"
                    // placeholder="Confirm Password"
                    type="password"
                  />
                </Form.Item>

                <p className="reg-txt-terms">
                  By registering, you agree with our 
                  <Link to="/terms" target="_blank" style={{textDecoration:"underline"}}>
                    {" "}
                    Terms and Conditions
                  </Link>
                  ,{" "}
                  <Link to="/policy" target="_blank" style={{textDecoration:"underline"}}>
                    Privacy
                  </Link>{" "}
                  {` and `}{" "}
                  <a href="/policy#cookies" target="_blank" style={{textDecoration:"underline"}}>
                    Cookie Policy
                  </a>
                  . <br />
                  We will only use your personal information to keep you updated
                  on exciting products and services.
                </p>
                <Form.Item>
                  <Button
                    className="reg-btn-register"
                    type="primary"
                    htmlType="submit"
                  >
                    Register
                  </Button>
                </Form.Item>
                <p className="reg-txt-signin">
                  To sell on Losode, register{" "}
                  <span
                    onClick={() => setUserType("seller")}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    <strong>here</strong>
                  </span>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <style jsx="true">
        {`
          .reg-register-seller-container {
            display: flex;
            height: 80vh;
            overflow: auto;
            -ms-overflow-style: none; 
            scrollbar-width: none;
          }
          .reg-register-container {
            flex: 1;
            text-align: left;
            margin: 30px 0;
          }
          .reg-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
            text-align: center;
          }
          .reg-txt-setup {
            font: normal 14px/20px DomaineSansText-Regular;
            color: #000;
            padding: 0 24px;
            text-align: center;
          }
          .ant-form-item {
            margin-bottom: 16px;
          }
          .ant-form-item-extra,
          .ant-form-item-explain {
            padding-top: 4px;
          }
          .ant-form-vertical .ant-form-item-label > label {
            font-family: "DomaineSansText-Regular";
            font-size: 14px;
          }
          .reg-inputs-container {
            margin: 0 24px;
            padding-bottom: 24px;
          }
          .reg-input,
          .reg-date {
            border: 0;
            border: 1px solid #808080;
            font-size: 16px;
            font-weight: 400;
            height: 48px;
            width: 100%;
          }
          .reg-input:focus,
          .reg-input:hover {
            border: 1px solid #000;
          }
          .ant-picker-input > input {
            font-size: 16px;
            font-weight: 300;
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            border: 2px solid #ccc;
            font-size: 16px;
            height: 48px;
            padding: 0px;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            display: flex;
            padding: 7px 10px;
          }
          .reg-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 100%;
            height: 48px;
            margin-top: 10px;
          }
          .reg-txt-signin {
            font: normal normal 14px DomaineSansText-Light;
            color: #000;
            margin-top: 0px;
            text-align: center;
          }
          .reg-txt-terms {
            font: normal normal normal 14px/22px DomaineSansText-Light;
          }
          .ant-picker:hover,
          .ant-picker-focused,
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: black;
            border-right-width: 1px !important;
          }

          .ant-form-item-extra {
            margin-bottom: -8px;
          }

          @media (max-width: 900px) {
            .reg-register-container {
              background: white;
              margin: 0;
              max-width: 100%;
              width: 100% !important;
              padding: 18px 11px;
            }
            .reg-regiseter-seller-contents {
              padding: 0;
            }
            .input,
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector,
            .reg-btn-register,
            .reg-date,
            .ant-picker-input > input {
              margin-top: 0 !important;
              font-size: 14px !important;
            }
            .ant-form-vertical .ant-form-item-label > label {
              font-size: 12px !important;
            }
            .ant-form-item {
              margin-bottom: 16px !important;
            }
            .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              display: flex;
              padding: 0 15px;
              align-items: center;
            }
            .ant-select-arrow {
              top: 53%;
              height: 10px;
              width: 16px;
            }
            .reg-txt-setup {
              font-size: 12px !important;
              width: 100%;
              line-height: 18px !important;
            }
            .reg-txt-register {
              margin-bottom: 8px;
              font-size: 24px !important;
            }
            .reg-txt-signin {
              font: normal normal 12px/14px DomaineSansText-Regular !important;
            }
            .reg-txt-terms {
              font: normal normal 12px/16px DomaineSansText-Regular !important;
            }

            .reg-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default RegisterBuyer;

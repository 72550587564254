import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const SellerHeader = ({
  scrollToRef,
  myBasicRef,
  myTipsRef,
  myFAQRef,
  ourSellersRef,
  setSellerSignInAuthModalVisible,
  setSellerAuthModalVisible
  // bannerInViewport
}) => {
  const navigate = useNavigate();

  const accountMenu = (
    <Menu className="seller-account-menu">
      <Link
        className="btn-dropdown"
        style={{backgroundColor: "#cccccc"}}
        to=""
        onClick={() => setSellerAuthModalVisible(true)}
      >
        Join Losode
      </Link>
      <Link
        to=""
        className="btn-dropdown"
        onClick={() =>  setSellerSignInAuthModalVisible(true)}
      >
        Sign In
      </Link>
    </Menu>
  );

  return (
    <>
      <div className="sellpage-navbar">
        {/* <div className="sellpage-discount-header">
          <p className="sellpage-vText">
            New Designers! 10% off our affordable photoshoots package. Book your
            session{" "}
            <a
              className="sellpage-discount-link"
              href="/book-photoshoot"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: "bold" }}
            >
              here
            </a>
          </p>
        </div> */}
        <div className="sellpage-navbar-content">
          <div className="sellpage-navbar-left">
            <div
              className="sellpage-navbar-logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            >
              <img src="/images/losode-no-tagline.png" alt="losode-logo" />
            </div>
            <div className="sellpage-navbar-title">Sell On Losode</div>
          </div>
          <div className="sellpage-navbar-right">
            <div
              className="sellpage-right-link"
              onClick={() => scrollToRef(myBasicRef)}
            >
              Get Started
            </div>
            <div
              className="sellpage-right-link"
              onClick={() => scrollToRef(myTipsRef)}
            >
              Tips For Listing
            </div>
            <div
              className="sellpage-right-link"
              onClick={() => scrollToRef(myFAQRef)}
            >
              FAQS
            </div>
            <div
              className="sellpage-right-link"
              onClick={() => scrollToRef(ourSellersRef)}
            >
              Our Designers
            </div>
            <div
              className="sellpage-right-link"
              onClick={() => setSellerSignInAuthModalVisible(true)}
              // onClick={() => setAuthChoiceModalVisible(true)}
            >
              My Store
            </div>

            <div className="sign-in-option">
              <Dropdown overlay={accountMenu} placement="bottomLeft">
                <div style={{ marginLeft: 20 }}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.6663 28V25.3333C26.6663 23.9188 26.1044 22.5623 25.1042 21.5621C24.104 20.5619 22.7475 20 21.333 20H10.6663C9.25185 20 7.8953 20.5619 6.8951 21.5621C5.89491 22.5623 5.33301 23.9188 5.33301 25.3333V28"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.0003 14.6667C18.9458 14.6667 21.3337 12.2789 21.3337 9.33333C21.3337 6.38781 18.9458 4 16.0003 4C13.0548 4 10.667 6.38781 10.667 9.33333C10.667 12.2789 13.0548 14.6667 16.0003 14.6667Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <DownOutlined
                    style={{ marginLeft: 10, fontSize: 15, fontWeight: 600 }}
                  />
                </div>
              </Dropdown>
            </div>

            {/* <Button
              type="primary"
              onClick={() => setSellerSignInAuthModalVisible(true)}
              // onClick={() => setAuthChoiceModalVisible(true)}
              style={{
                display: bannerInViewport ? "none" : "block",
                height: "40px",
                fontSize: "15px",
              }}
            >
              List an item
            </Button> */}
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .seller-homepage-container {
          width: 100%;
          font-family: DomaineSansText-Regular;
          padding-top: 120px;
        }
        .sellpage-discount-header {
          align-items: center;
          background: #e9e9e9;
          display: flex;
          height: 40px;
          justify-content: center;
          padding-left: 50px;
          padding-right: 35px;
        }
        .sellpage-vText {
          font: normal normal 14px DomaineSansText-Light;
          margin-top: 15px;
        }
        .sellpage-code {
          color: #800000;
          font-weight: bold;
          font-style: normal;
        }

        .sellpage-discount-link {
          text-decoration: underline;
          font: normal normal normal 14px DomaineSansText-Light;
          letter-spacing: 0px;
          color: #000000;
        }
        .sellpage-navbar {
          font-family: "DomaineSansText-Light";
          font-size: 15px;
          align-items: center;
          background: white;
          width: 100%;
          position: fixed;
          z-index: 100;
          box-shadow: 0 2px 10px 0 #05050526;
        }
        .sellpage-navbar-content {
          display: flex;
          justify-content: space-between;
          padding: 0 25px;
          align-items: center;
          background: white;
          width: 100%;
          height: 80px;
        }
        .sellpage-navbar-title {
          font-size: 18px;
          font-weight: bold;
          font-family: "DomaineSansText-Regular";
        }
        .sellpage-navbar-left {
          display: flex;
          align-items: center;
          gap: 25px;
        }
        .sellpage-navbar-right {
          display: flex;
          gap: 30px;
          align-items: center;
        }
        .sellpage-navbar-logo img {
          height: 30px;
        }
        .sellpage-left-link,
        .sellpage-right-link {
          cursor: pointer;
        }
        .sellpage-left-link:hover,
        .sellpage-right-link:hover {
          color: #800000;
          text-decoration: underline;
        }

        .sign-in-option {
          cursor: pointer;
        }

        .seller-account-menu {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin-top: 30px;
        }

        .btn-dropdown {
          width: 150px;
          padding: 10px 0;
          text-align: center;
          margin: 0;
          font-family: DomaineSansText-Regular;
          font-size: 16px;
        }
        .ant-dropdown {
          position: fixed;
        }

        @media (max-width: 900px) and (min-width: 640px) {
          .seller-info-board-list {
            flex-direction: row;
          }
          .sellpage-navbar-right {
            gap: 12px;
            font-size: 14px;
          }
          .sellpage-navbar-title {
            font-size: 18px;
          }
          .seller-homepage-container {
            padding-top: 80px;
          }
        }
      `}</style>
    </>
  );
};

export default SellerHeader;

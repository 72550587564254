// import { useEffect } from "react";
// import { useMediaQuery } from "react-responsive";
import StaticPageTemplate from "./StaticPageTemplate";
// import { useMediaQuery } from "react-responsive";
import { Collapse, 
  // Form, Input, Select, Col, Row,  Button, Space 
} from "antd";
// import { countries } from "../assets/constants";
//   import axios from "axios";
// import { fetchCities, fetchStates } from "../utils/getStates";
//   import getLatLng from "../utils/getLatLng";
// import { useState } from "react";
// import { useEffect } from "react";

// const { Option } = Select;

// const { TextArea } = Input;

const ContactUs = () => {
  // const [contactForm] = Form.useForm();
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  // const [countryValue, setCountryValue] = useState("Nigeria");

  // const [states, setStates] = useState([]);
  // const [selectedState, setSelectedState] = useState();
  // const [cities, setCities] = useState([]);

  // const onSelectCountry = async () => {
  //   if (countryValue && states.length === 0) {
  //     const res = await fetchStates(countryValue);
  //     setStates(res);
  //   }
  // };

  // const getCities = async () => {
  //   if (selectedState) {
  //     setCities(await fetchCities(selectedState, countryValue));
  //   }
  // };

  // useEffect(() => {
  //   onSelectCountry();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [countryValue]);

  // useEffect(() => {
  //   getCities();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedState]);

  return (
    <div>
      <StaticPageTemplate title={"Contact Us"}>
        <div className="static-content-container">
          <div>
            <p>
              For any enquiries, please contact us. We're available 24 hours, 7
              days a week.
            </p>

            <h4 style={{ fontFamily: "DomaineSansText-Black", lineHeight: "30px" }}>
              For Customers
            </h4>
            <p>
              Email us at{" "}
              <a
                href="mailto:hello@losode.com"
                style={{
                  fontWeight: "bold",
                  fontFamily: "DomaineSansText-Black",
                  textDecoration: "underline",
                }}
              >
                hello@losode.com
              </a>
              <p className="contact-info">Call us on <a href="tel:+2342013306011">+234 2013 306 011</a></p>
            </p>
            
            
            

            <div
              style={{ borderTop: "1px solid #c4c4c4", margin: "16px 0" }}
            ></div>

            <h4 style={{fontFamily:'DomaineSansText-Black'}}>For Designers</h4>
            <p>Email us at <a href="mailto:designers@losode.com"  style={{fontWeight:'bold', fontFamily:'DomaineSansText-Black', textDecoration:'underline'}}>designers@losode.com</a>
            <p className="contact-info">Call us on <a href="tel:+2342013306011">+234 2013 306 011</a></p>
            </p>
            
            {/* <p>Call us on <a href="tel:0800xxxxxxx">0800 xxxx xxxx</a></p> */}
          </div>

          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel header="Complaint Policy" key="1">
              <div>
                <h3>Introduction</h3>
                <p>
                  We pride ourselves on our ability to offer fashionable yet
                  affordable clothing, shoes, and accessories to both men and
                  women and value customer feedback, using it continuously to
                  improve our services.
                </p>

                <h3>How To Notify Us of Your Concerns</h3>
                <p>
                  Customers can send complaints to us through{" "}
                  <a
                    href="mailto:hello@losode.com"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "DomaineSansText-Black",
                      textDecoration: "underline",
                    }}
                  >
                    hello@losode.com
                  </a>
                  , telephone and contact us form
                </p>

                <h3>Acknowledging Your Concern</h3>
                <p>
                  We will send an acknowledgment email to the customer within 24
                  hours, confirming receipt of the complaints and prioritizing
                  them.
                </p>

                <h3>Investigating Your Concern</h3>
                <p>
                  Our dedicated team will thoroughly investigate the issue
                  raised. The investigation may involve us reviewing the details
                  provided and looking into other relevant information.
                </p>

                <h3>Resolution Timescales</h3>
                <p>
                  We aim to resolve complaints within 12 working days from the
                  date of acknowledgment. If more time is needed due to the
                  complexity of the issue, we will inform the customer of the
                  delay and provide an update on the progress.
                </p>

                <p>
                  We can only process the relevant refund and exchange for items
                  that have been returned and received as stated in our approach
                  to the refund and exchange section.
                </p>

                <h3>Communication</h3>
                <p>
                  We will maintain regular communication with the customer
                  throughout the complaint process, keeping them informed of the
                  investigation and resolution progress. If a valid issue is
                  identified, we will offer appropriate remedies, which may
                  include requesting a refund or exchange.
                </p>

                <h3>Feedback</h3>
                <p>
                  After the complaint is resolved, customers will have the
                  opportunity to provide feedback on their experience with the
                  complaints process.
                </p>

                <h3>Privacy</h3>
                <p>
                  All customer information provided during the complaints
                  process will be treated in accordance with our privacy policy.
                  We regularly monitor complaints trends to identify recurring
                  issues and implement preventive measures.
                </p>

                <h3>Contact Information</h3>
                <p>Please send any complaints to us at:</p>
                <p>
                  Email:{" "}
                  <a
                    href="mailto:hello@losode.com"
                    style={{
                      fontWeight: "bold",
                      fontFamily: "DomaineSansText-Black",
                      textDecoration: "underline",
                    }}
                  >
                    hello@losode.com
                  </a>
                </p>
              </div>
            </Collapse.Panel>
          </Collapse>

          {/* <div style={{ paddingTop: "24px" }}>
            <h3
              style={{
                textAlign: "center",
                marginBottom: "16px",
                fontSize: "20px",
              }}
            >
              Contact Us Form
            </h3>
            <Form
              form={contactForm}
              scrollToFirstError
              layout="vertical"
              // onFinish={onSubmit}
              // initialValues={{
              // brand_country: "Nigeria",
              // brand_phone_dial: "+234",
              // }}
              style={{
                padding: "24px",
                border: "1px solid black",
                margin: "auto 40px",
              }}
            >
              <h3 style={{ textAlign: "center", marginBottom: "16px" }}>
                Customer Complaint Form
              </h3>
              <Row
                className="form-row"
                gutter={[30, 0]}
                // style={{
                //     background: "white",
                // }}
              >
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      {
                        pattern: /^[a-zA-Z]+$/g,
                        message: "Only letters are allowed",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your first name"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      {
                        pattern: /^[a-zA-Z]+$/g,
                        message: "Only letters are allowed",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your last name"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Gender is required",
                      },
                    ]}
                  >
                    <Select placeholder="Select your gender" className="select">
                      <Select.Option value="Male">Male</Select.Option>
                      <Select.Option value="Female">Female</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="brand_email"
                    label="Brand Email Address"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        type: "email",
                        message: "Use a valid email address",
                      },
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your brand email address"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="brand_country"
                    label="Country"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Country is required",
                      },
                    ]}
                  >
                    <Select
                      className="select"
                      placeholder="Select Country"
                      optionFilterProp="children"
                      defaultValue={countryValue}
                      value={countryValue}
                      onChange={(value) => setCountryValue(value)}
                      disabled
                    >
                      {countries.map(({ country, icon }) => (
                        <Option key={country} value={country}>
                          <img
                            className="icon-flag"
                            src={icon}
                            alt={country}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                          {country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="brand_phone"
                    label="Phone Number"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Space.Compact style={{ width: "100%" }}>
                      <Select
                        defaultValue={"+234"}
                        style={{
                          width: "35%",
                        }}
                        onChange={(e) =>
                          contactForm.setFieldsValue({
                            brand_phone_dial: e.target.value,
                          })
                        }
                        value={contactForm.getFieldValue("brand_phone_dial")}
                      >
                        <Option
                          key={countries[0].dial}
                          value={countries[0].dial}
                        >
                          <img
                            className="icon-flag"
                            src={countries[0].icon}
                            alt={countries[0].country}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                          {countries[0].dial}
                        </Option>
                      </Select>
                      <Input
                        className="input phone-input"
                        type="number"
                        placeholder="Enter phone number"
                        onChange={(e) =>
                          contactForm.setFieldsValue({ phone: e.target.value })
                        }
                        value={contactForm.getFieldValue("phone")}
                      />
                    </Space.Compact>
                  </Form.Item>
                </Col>

                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="brand_state"
                    label="State"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select
                      className="select"
                      placeholder="Select your state"
                      onChange={(value) => setSelectedState(value)}
                    >
                      {states?.map(({ state_name: state }) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="brand_city"
                    label="City"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select className="select" placeholder="Select your city">
                      {cities?.map((city, index) => (
                        <Option key={index} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="complaint_details"
                    label="Complaint Details"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <TextArea
                      className="reg-input"
                      placeholder="Enter your complaint"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <h3
                style={{
                  padding: "16px 0",
                  marginBottom: "24px",
                  borderBottom: "1px solid #c4c4c4",
                }}
              >
                Order Information
              </h3>

              <Row
                className="form-row"
                gutter={[30, 0]}
                // style={{
                //     background: "white",
                // }}
              >
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="orderno"
                    label="Order Number"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      // {
                      // pattern: /^[a-zA-Z]+$/g,
                      // message: "Only letters are allowed",
                      // },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your order number"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="orderid"
                    label="OrderId"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      // {
                      // pattern: /^[a-zA-Z]+$/g,
                      // message: "Only letters are allowed",
                      // },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your order id"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="order_price"
                    label="Order Price"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your order price"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <h3
                style={{
                  padding: "16px 0",
                  marginBottom: "24px",
                  borderBottom: "1px solid #c4c4c4",
                }}
              >
                Bank Information
              </h3>

              <Row
                className="form-row"
                gutter={[30, 0]}
                // style={{
                //     background: "white",
                // }}
              >
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="bank_acc_name"
                    label="Bank Account Name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      // {
                      // pattern: /^[a-zA-Z]+$/g,
                      // message: "Only letters are allowed",
                      // },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your account name"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="bank_acc_no"
                    label="Bank Account Number"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                      // {
                      // pattern: /^[a-zA-Z]+$/g,
                      // message: "Only letters are allowed",
                      // },
                    ]}
                  >
                    <Input
                      className="reg-input"
                      placeholder="Enter your account number"
                    />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="bank_name"
                    label="Bank name"
                    labelAlign="top"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Select placeholder="Select your Bank" className="select">
                      <Select.Option value="Male">Male</Select.Option>
                      <Select.Option value="Female">Female</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <Button
                  className="reg-btn-register"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div> */}
        </div>
      </StaticPageTemplate>
      <style jsx="true">{`
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }

        .ant-collapse,
        .ant-collapse > .ant-collapse-item {
          border: 0;
        }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          flex-direction: row-reverse;
          font-size: 16px;
          font-family: "DomaineSansText-Black";
          justify-content: space-between;
          padding: 12px 0;
        }
        .ant-collapse-content {
          background-color: transparent;
          border-top-color: #c4c4c4;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 24px 0;
        }

        .reg-input,
        .reg-date {
          // border: 0;
          border: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          height: 48px;
          width: 100%;
          background: transparent;
        }
        .reg-input-phone {
          // border: 0;
          border: 1px solid #ccc;
          font-size: 16px;
          font-weight: 400;
          height: fit;
          width: 100%;
          padding: 0px; /* Adjust padding as needed */
        }
        .reg-input-phone .ant-input {
          height: 48px;
          border: none;
        }
        .phone-input {
          background: transparent;
        }

        .ant-form-item-label
          > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
          )::before {
          display: none;
          content: " ";
        }

        .reg-input-phone:focus,
        .reg-input-phone:hover {
          border: 1px solid #000;
        }
        .reg-input:focus,
        .reg-input:hover {
          border: 1px solid #000;
        }

        .reg-btn-register {
          font: normal 16px DomaineSansText-Regular;
          width: 300px;
          height: 48px;
          margin-top: 10px;
        }

        .select-multiple {
          width: 90px;
          height: fit;
        }
        .ant-form-item {
          margin-bottom: 20px;
        }
        .ant-form-item-extra,
        .ant-form-item-explain {
          padding-top: 4px;
        }
        .ant-form-vertical .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 14px;
          fon-weight: bold;
        }
        .ant-form-item-label > label {
          font-family: DomaineSansText-Light;
          font-size: 16px;
          fon-weight: bold;
          font: 700;
        }
        .ant-picker-input > input {
          font-size: 16px;
          font-weight: 300;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #ccc;
          font-size: 16px;
          height: 48px;
          padding: 0px;
          background: transparent;
        }
        .prefix-select {
          width: 100px;
          height: 48px;
          border: none;
          outline: none;
        }
        .prefix-select .ant-select {
          width: 90px;
          height: 48px;
          border: 0;
          outline: 0;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 7px 10px;
          align-items: center;
        }
        .ant-picker:hover,
        .ant-picker-focused,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: black;
          border-right-width: 1px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 48px;
          border: 1px solid #ccc;
        }
        .ant-input-group-addon:first-child {
          border: 0;
          background: transparent;
          border-right: 1px solid #ccc;
        }
        .contact-info{
          // line-height: 30px;
          margin-bottom: 0;
          margin-top: 10px;
        }

        @media (max-width: 640px) {
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
          .ant-select-selection-item {
            display: flex;
            align-items: center;
          }
        }
      `}</style>
    </div>
  );
};

export default ContactUs;

const sizes = (gender, cat, category) => {
  const common = ["Kaftans", "Danshiki", "Tunics"];
  if (category === "Fabrics") {
    return [
      { id: 1, letter: "3 Yards" },
      { id: 2, letter: "5 Yards" },
      { id: 3, letter: "6 Yards" },
      { id: 4, letter: "9 Yards" },
      { id: 5, letter: "15 Yards" },
      { id: 6, letter: "20 Yards" },
      { id: 7, letter: "25 Yards" },
    ];
  }

  if (common.includes(cat)) {
    return [
      { id: 1, letter: "S" },
      { id: 2, letter: "M" },
      { id: 3, letter: "L" },
      { id: 4, letter: "XL" },
      { id: 5, letter: "2XL" },
      { id: 6, letter: "MT(Medium Tall" },
    ];
  }

  if (gender === "Women") {
    const womenSizeMappings = {
      "Hoodies and Sweatshirts,Tops and Blouses,Bridalwear,Kimonos,Jumpers,Shirts and Blouses":
        [
          { id: 1, letter: "UK 4-XXS" },
          { id: 2, letter: "UK 6-XS" },
          { id: 3, letter: "UK 8-S" },
          { id: 4, letter: "UK 10-M" },
          { id: 5, letter: "UK 12-L" },
          { id: 6, letter: "UK 14-XL" },
          { id: 7, letter: "UK 16-2XL" },
          { id: 8, letter: "UK 18-3XL" },
          { id: 9, letter: "UK 20-4XL" },
          { id: 10, letter: "UK 22-5XL" },
          { id: 11, letter: "UK 24-6XL" },
          { id: 12, letter: "UK 26-7XL" },
          { id: 13, letter: "UK 28-8XL" },
          { id: 14, letter: "UK 30-9XL" },
          { id: 15, letter: "UK 32-10XL" },
          { id: 15, letter: "UK 34-11XL" },
          { id: 15, letter: "UK 36-12XL" },
        ],
      Dresses: [
        { id: 1, letter: "UK 4-XXS" },
        { id: 2, letter: "UK 6-XS" },
        { id: 3, letter: "UK 8-S" },
        { id: 4, letter: "UK 10-M" },
        { id: 5, letter: "UK 12-L" },
        { id: 6, letter: "UK 14-XL" },
        { id: 7, letter: "UK 16-2XL" },
        { id: 8, letter: "UK 18-3XL" },
        { id: 9, letter: "UK 20-4XL" },
        { id: 10, letter: "UK 22-5XL" },
        { id: 11, letter: "UK 24-6XL" },
        { id: 12, letter: "UK 26-7XL" },
        { id: 13, letter: "UK 28-8XL" },
        { id: 14, letter: "UK 30-9XL" },
      ],
      "Co-ords,Swimwear and Beachwear,Loungewear,Multipacks,Sportswear,Tracksuits and Joggers,Suits and Tailoring,Shorts":
        [
          { id: 1, letter: "UK-XS" },
          { id: 2, letter: "UK-S" },
          { id: 3, letter: "UK-M" },
          { id: 4, letter: "UK-L" },
          { id: 5, letter: "UK-XL" },
          { id: 6, letter: "UK-2XL" },
          { id: 7, letter: "UK-3XL" },
          { id: 9, letter: "UK 20-4XL" },
          { id: 10, letter: "UK 22-5XL" },
          { id: 11, letter: "UK 24-6XL" },
          { id: 12, letter: "UK 26-7XL" },
          { id: 13, letter: "UK 28-8XL" },
          { id: 14, letter: "UK 30-9XL" },
          { id: 14, letter: "UK 32-10XL" },
          { id: 14, letter: "UK 34-11XL" },
          { id: 14, letter: "UK 36-12XL" },
        ],
      "Coats and Jackets": [
        { id: 1, letter: "UK-XS" },
        { id: 2, letter: "UK-S" },
        { id: 3, letter: "UK-M" },
        { id: 4, letter: "UK-L" },
        { id: 5, letter: "UK-XL" },
        { id: 6, letter: "UK-2XL" },
        { id: 7, letter: "UK-3XL" },
        { id: 8, letter: "UK 20-4XL" },
        { id: 9, letter: "UK 22-5XL" },
        { id: 10, letter: "UK 24-6XL" },
        { id: 11, letter: "UK 26-7XL" },
        { id: 12, letter: "UK 28-8XL" },
        { id: 13, letter: "UK 30-9XL" },
        { id: 14, letter: "UK 32-10XL" },
      ],
      "Lingerie and Nightwear,Jumpsuits and Playsuits": [
        { id: 1, letter: "UK 4-XXS" },
        { id: 2, letter: "UK 6-XS" },
        { id: 3, letter: "UK 8-S" },
        { id: 4, letter: "UK 10-M" },
        { id: 5, letter: "UK 12-L" },
        { id: 6, letter: "UK 14-XL" },
        { id: 7, letter: "UK 16-2XL" },
        { id: 8, letter: "UK 18-3XL" },
      ],
      "Jeans,Trousers,Skirts": [
        { id: 1, letter: "UK 2-XS" },
        { id: 2, letter: "UK 4-S" },
        { id: 3, letter: "UK 6-M" },
        { id: 4, letter: "UK 8-M" },
        { id: 5, letter: "UK 10-M" },
        { id: 6, letter: "UK 12-L" },
        { id: 7, letter: "UK 14-XL" },
        { id: 8, letter: "UK 16-2XL" },
        { id: 9, letter: "UK 20-4XL" },
        { id: 10, letter: "UK 22-5XL" },
        { id: 11, letter: "UK 24-6XL" },
        { id: 12, letter: "UK 26-7XL" },
        { id: 13, letter: "UK 28-8XL" },
        { id: 14, letter: "UK 30-9XL" },
        { id: 14, letter: "UK 32-10XL" },
        { id: 14, letter: "UK 34-11XL" },
        { id: 14, letter: "UK 36-12XL" },
      ],
      Footwear: [
        { id: 1, letter: "UK 2" },
        { id: 2, letter: "UK 3" },
        { id: 3, letter: "UK 4" },
        { id: 4, letter: "UK 5" },
        { id: 5, letter: "UK 6" },
        { id: 6, letter: "UK 7" },
        { id: 7, letter: "UK 8" },
        { id: 8, letter: "UK 9" },
      ],
      "Socks and Tights": [
        { id: 1, letter: "S" },
        { id: 2, letter: "M" },
        { id: 3, letter: "L" },
      ],
    };

    for (const [key, value] of Object.entries(womenSizeMappings)) {
      if (key.split(",").includes(cat) || key === category) {
        return value;
      }
    }
  }

  if (gender === "Men") {
    const menSizeMappings = {
      "Hoodies and Sweatshirt": [
        { id: 1, letter: "UK-3XS" },
        { id: 2, letter: "UK-2XS" },
        { id: 3, letter: "UK-XS" },
        { id: 4, letter: "UK-S" },
        { id: 5, letter: "UK-M" },
        { id: 6, letter: "UK-L" },
        { id: 7, letter: "UK-XL" },
        { id: 8, letter: "UK-2XL" },
        { id: 9, letter: "UK-3XL" },
        { id: 10, letter: "UK-4XL" },
        { id: 11, letter: "UK-5XL" },
        { id: 12, letter: "UK-6XL" },
        { id: 13, letter: "UK-7XL" },
        { id: 14, letter: "UK-8XL" },
        { id: 15, letter: "UK-9XL" },
        { id: 16, letter: "UK-10XL" },
        { id: 17, letter: "UK-11XL" },
        { id: 18, letter: "UK-12XL" },
      ],
      "T-Shirts and Vests,Blazers,Shirts and T-Shirts,Vests,Polo Shirts,Shirts": [
        { id: 1, letter: "XS" },
        { id: 2, letter: "S" },
        { id: 3, letter: "M" },
        { id: 4, letter: "L" },
        { id: 5, letter: "XL" },
        { id: 6, letter: "2XL" },
        { id: 7, letter: "3XL" },
        { id: 8, letter: "4XL" },
        { id: 9, letter: "5XL" },
        { id: 10, letter: "6XL" },
        { id: 11, letter: "7XL" },
        { id: 12, letter: "8XL" },
        { id: 13, letter: "9XL" },
        { id: 14, letter: "10XL" },
        { id: 15, letter: "11XL" },
        { id: 16, letter: "12XL" },
      ],
      "Coats and Jackets,Blazers,Suits and Tailoring": [
        { id: 1, letter: "UK-32" },
        { id: 2, letter: "UK-34" },
        { id: 3, letter: "UK-36" },
        { id: 4, letter: "UK-38" },
        { id: 5, letter: "UK-40" },
        { id: 6, letter: "UK-42" },
        { id: 7, letter: "UK-44" },
        { id: 8, letter: "UK-46" },
      ],
      "Jeans,Trousers,Trousers and Chinos": [
        { id: 1, letter: "XXS" },
        { id: 2, letter: "XS" },
        { id: 3, letter: "S" },
        { id: 4, letter: "M" },
        { id: 5, letter: "L" },
        { id: 6, letter: "XL" },
        { id: 7, letter: "2XL" },
        { id: 8, letter: "3XL" },
        { id: 9, letter: "4XL" },
      ],
      "Co-ords,Joggers and Cardigans,Tracksuits and Joggers,Loungewear,Multipacks,Sportswear":
        [
          { id: 1, letter: "M" },
          { id: 2, letter: "L" },
          { id: 3, letter: "XL" },
          { id: 4, letter: "2XL" },
          { id: 5, letter: "3XL" },
          { id: 6, letter: "4XL" },
          { id: 7, letter: "5XL" },
          { id: 8, letter: "6XL" },
          { id: 9, letter: "7XL" },
          { id: 10, letter: "8XL" },
          { id: 11, letter: "9XL" },
          { id: 12, letter: "10XL" },
          { id: 13, letter: "11XL" },
          { id: 14, letter: "12XL" },
        ],
      "Shorts,Swimwear,Underwear": [
        { id: 1, letter: "UK-3XS" },
        { id: 2, letter: "UK-XXS" },
        { id: 3, letter: "UK-XS" },
        { id: 4, letter: "UK-S" },
        { id: 5, letter: "UK-M" },
        { id: 6, letter: "UK-L" },
        { id: 7, letter: "UK-XL" },
        { id: 8, letter: "UK-2XL" },
        { id: 9, letter: "UK-3XL" },
        { id: 10, letter: "UK-4XL" },
        { id: 11, letter: "UK-5XL" },
        { id: 12, letter: "UK-6XL" },
        { id: 13, letter: "UK-7XL" },
        { id: 14, letter: "UK-8XL" },
        { id: 15, letter: "UK-9XL" },
        { id: 16, letter: "UK-10XL" },
        { id: 17, letter: "UK-11XL" },
        { id: 18, letter: "UK-12XL" },
      ],
      Socks: [
        { id: 1, letter: "One Size" },
        { id: 2, letter: "S/M" },
        { id: 3, letter: "M/L" },
      ],
      Footwear: [
        { id: 1, letter: "UK-3" },
        { id: 2, letter: "UK-4" },
        { id: 3, letter: "UK-5" },
        { id: 4, letter: "UK-6" },
        { id: 5, letter: "UK-7" },
        { id: 6, letter: "UK-7.5" },
        { id: 7, letter: "UK-8" },
        { id: 8, letter: "UK-8.5" },
        { id: 9, letter: "UK-9" },
        { id: 10, letter: "UK-9.5" },
        { id: 11, letter: "UK-10" },
        { id: 12, letter: "UK-10.5" },
        { id: 13, letter: "UK-11" },
        { id: 14, letter: "UK-12" },
        { id: 15, letter: "UK-13" },
        { id: 16, letter: "UK-14" },
      ],
    };

    for (const [key, value] of Object.entries(menSizeMappings)) {
      if (key.split(",").includes(cat) || key === category) {
        return value;
      }
    }
  }

  return [];
  
};

export default sizes;
